<template>
  <div class="container">
    <div>
      <router-link :to="{ name: 'dashboard-hitler' }">
        <div class="all-title-head">
          ฝากเงิน
        </div>
        <div class="dtx2">
          <span>
            <i
              class="fas fa-angle-left fa-2x"
              style="color: #fff;"
            />
          </span>
        </div>
      </router-link>
      <div
        v-if="agent.status_dip === '0'"
        class="text-center"
      >
        <hr>
        <h5 style="color: red">
          *ขออภัยขณะนี้เว็บไซต์ได้ทำการปิดระบบฝากเงิน
        </h5>
      </div>
      <hr>

      <div>
        <div
          class="row text-center"
        >
          <div
            class="col-6"
          >
            <router-link
              :to="{ name: 'deposit-next' }"
            >
              <button
                :disabled="agent.status_dip === '0'"
                class="btnlogout mb-4 mt-1"
              >
                ฝากเงินธนาคารไทยออโต้
              </button>
            </router-link>
          </div>

          <div
            class="col-6"
          >
            <router-link
              :to="{ name: 'truewallet' }"
            >
              <button
                :disabled="agent.status_dip === '0'"
                class="btnlogout mb-4 mt-1"
              >
                True Wallet
              </button>
            </router-link>
          </div>

          <div
            v-if="userData.acc_laos"
            class="col-6"
          >
            <router-link
              :to="{ name: 'deposit-laos', params: { currency: 'lak' } }"
            >
              <button
                :disabled="agent.status_dip === '0'"
                class="btnlogout mb-4 mt-1"
              >
                ຝາກເງິນກີບທະນາຄານລາວອໍໂຕ້
              </button>
            </router-link>
          </div>

          <div
            v-if="userData.acc_laos"
            class="col-6"
          >
            <router-link
              :to="{ name: 'deposit-laos', params: { currency: 'bath' } }"
            >
              <button
                :disabled="agent.status_dip === '0'"
                class="btnlogout mb-4 mt-1"
              >
                ຝາກເງິນບາດທະນາຄານລາວອໍໂຕ້
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      ref="my-modal"
      hide-footer
      class="text-center color_ra"
    >
      <div class="d-block text-center">
        <b-img
          src="/qrcode.jpg"
          alt=""
          fluid
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BImg } from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BImg,
  },
  data() {
    return {
      selected: [],
      agent: {},
      userData: JSON.parse(localStorage.getItem('userData')),
      User: '',
    }
  },
  mounted() {
    this.getUser(this.userData)
    this.GetAgentData()
  },
  methods: {
    GetAgentData() {
      this.$http
        .get('https://api.ufabangkok.com/api/agent/show')
        .then(response => {
          // console.log(response.data.ListGames)
          this.agent = response.data
        })
        .catch(error => console.log(error))
    },
    showModal2() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    getUser(userData) {
      this.$http
        .get(`https://api.ufabangkok.com/api/users/show/${userData.userID}`)
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.User = response.data
        })
        .catch(error => console.log(error))
    },
  },
}
</script>

<style scoped>
.container {
  max-width: 540px;
}

.balance-cl {
  background-color: #d91f20;
  border-radius: 15px;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: #fff;
  position: relative;
}
.balance-cl2 {
  background-color: #ffffff;
  border: 1px solid #d91f20;
  border-radius: 15px;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: #000;
  position: relative;
}
p.highlight {
  line-height: 1.9;
  font-size: 1.8rem;
  color: #fff;
  margin-top: 10px;
}
p.highlight1 {
  line-height: 1.9;
  font-size: 1.8rem;
  color: #000;
  margin-top: 10px;
}
.pic {
  max-width: 210px;
}
@media only screen and (max-width: 600px) {
  .pic {
    margin-top: 35px;
    max-width: 160px;
  }
  .pic2 {
    margin-top: 35px;
    max-width: 40px;
  }
}
.neon1 {
  background: rgb(27, 12, 27);
  background: radial-gradient(circle, rgba(27, 12, 27, 0) 0%, #09081f 100%);
  border-radius: 5px;
  padding: 5px 5px;
  /* border: 1px solid blueviolet; */
  /* box-shadow: blueviolet 1px 1px 15px; */
}
.m-space {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 35px;
  margin-left: 1px;
  margin-right: 1px;
}
i {
  margin-top: 22px;
  justify-content: center;
}
.dtx {
  position: relative;
  display: flex;
  margin-top: -40px;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  align-items: center;
}
.dtx2 {
  position: relative;
  display: flex;
  margin-left: 5px;
  margin-top: -58px;
  justify-content: left;
  color: #000;
  font-weight: 600;
  align-items: left;
}

/* asdasd */
</style>
